import { IntlProvider, ReactIntlErrorCode } from 'react-intl'
import { useRouter } from 'next/router'
import { useEffect, useMemo, useRef } from 'react'

import { init } from '@amplitude/analytics-browser'

import 'swiper/css'
import '../styles/index.scss'

import ruLocale from '../src/locales/ru.json'
import enLocale from '../src/locales/en.json'
import kzLocale from '../src/locales/kz.json'
import brLocale from '../src/locales/br.json'
import esLocale from '../src/locales/es.json'
import uaLocale from '../src/locales/ua.json'

import { getCookie, setCookies } from '@utils/coockies'
import $amplitude, { $amplitudeSetUserId } from '@utils/amplitude'
import {
  eventStoriesClick,
  eventStoriesTryForFreeButtonClick,
} from '@utils/amplitudeEvents'

function MyApp({ Component, pageProps }) {
  const { locale, route } = useRouter()
  const routeRef = useRef(null)

  const messages = useMemo(() => {
    switch (locale) {
      case 'ru':
        return ruLocale.messages
      case 'en':
        return enLocale.messages
      case 'kz':
        return kzLocale.messages
      case 'br':
        return brLocale.messages
      case 'es':
        return esLocale.messages
      case 'ua':
        return uaLocale.messages
      default:
        return enLocale.messages
    }
  }, [locale])

  const handleIntlError = err => {
    if (err.code === ReactIntlErrorCode.MISSING_DATA) {
      return
    }
    if (err.code === ReactIntlErrorCode.FORMAT_ERROR) {
      return
    }
    console.error(err)
  }

  useEffect(() => {
    if (routeRef.current !== route) {
      $amplitude('[Landing] Page Visited', {
        page_name: window.location.pathname,
      })

      routeRef.current = route
    }
  }, [routeRef, route])

  useEffect(() => {
    const url = new URL(location.href)
    const value = url.searchParams.get('from')
    const gclid = url.searchParams.get('gclid')
    const yclid = url.searchParams.get('yclid')

    if (yclid) setCookies('yclid', yclid, 360, '/', '.livedune.com')
    if (gclid) setCookies('gclid', gclid, 360, '/', '.livedune.com')
    if (value) setCookies('from', value, null, null, '.livedune.com')

    // собираем utm метки
    const utmList = []
    for (let [key, value] of url.searchParams.entries()) {
      if (key.startsWith('utm_')) utmList.push(`${key}=${value}`)
    }

    if (utmList.length) {
      setCookies('TILDAUTM', utmList.join('|||'), 30, '/', '.livedune.com')
    } else {
      const referrer = document.referrer
      const hasCookie = getCookie('TILDAUTM')

      // если utm меток нет и есть referrer
      if (referrer && !hasCookie) {
        const medium =
          referrer.includes('yandex') ||
          referrer.includes('google') ||
          referrer.includes('ya.ru')
            ? 'organic'
            : 'referral'

        setCookies(
          'TILDAUTM',
          `utm_source=${referrer}|||utm_medium=${medium}`,
          30,
          '/',
          '.livedune.com'
        )
      }
    }
  }, [])

  useEffect(() => {
    const map = {
      1: 'opportunities',
      2: 'advantages',
      3: 'feedback',
      4: 'try_for_free',
    }

    const handleMessage = e => {
      if (e.data && e.data.command) {
        const { storyId, stories_id } = e.data
        const mappedValue = map[storyId || stories_id]

        if (mappedValue) {
          if (e.data.command === 'carrotquest.clickProductLink') {
            eventStoriesTryForFreeButtonClick(mappedValue)
          } else if (e.data.command === 'carrotquest.clickStory') {
            eventStoriesClick(mappedValue)
          }
        }
      }
    }

    window.addEventListener('message', handleMessage)

    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [])

  // Amplitude init
  useEffect(() => {
    // const key = 'fdc2f27bdb3746e670c85c7e2e8f6874' //test
    const key = 'aacb13c81d7188d690e5619bc8bc9872' //prod

    const userId = getCookie('ld_ui')?.padStart(5, '0')
    if (userId) $amplitudeSetUserId(userId)

    init(key, userId, {
      autocapture: {
        attribution: true,
        elementInteractions: false,
        fileDownloads: true,
        formInteractions: false,
        pageViews: true,
        sessions: true,
      },
      flushIntervalMillis: 100,
    })
  }, [])

  return (
    <IntlProvider
      locale={locale}
      defaultLocale={'en'}
      messages={messages}
      onError={handleIntlError}
    >
      <Component {...pageProps} />

      <div
        id="g_id_onload"
        data-client_id="55704283602-djppl8kbacu73ovq7tf80i8cselqdp8j.apps.googleusercontent.com"
        data-context="signin"
        data-state_cookie_domain="https://pro.livedune.com/"
        data-login_uri="https://pro.livedune.com/auth/google"
        data-itp_support="true"
      />
    </IntlProvider>
  )
}

export default MyApp
