export const setCookies = (name, value, days, path, domain, secure) => {
  let cookie = `${name}=${encodeURIComponent(value)}`

  // Add expiry date
  if (days) {
    const expiry = new Date()
    expiry.setDate(expiry.getDate() + days)
    cookie += `; expires=${expiry.toUTCString()}`
  }

  // Add Path, Domain, and Secure
  if (path) cookie += `; path=${path}`
  if (domain) cookie += `; domain=${domain}`
  if (secure) cookie += `; secure`

  // Set an HTTP cookie
  document.cookie = cookie
}

export const getCookie = name => {
  const cookies = document.cookie.split(';')
  for (const element of cookies) {
    let c = element.trim().split('=')
    if (c[0] === name) {
      return c[1]
    }
  }
  return undefined
}

export const deleteAllCookies = () => {
  const cookies = document.cookie.split(';')

  cookies.forEach(cookie => {
    const eqPos = cookie.indexOf('=')
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
    document.cookie = `${name} =;expires=Thu, 01 Jan 1970 00:00:00 GMT`
  })
}
