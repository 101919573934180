import { track, setUserId } from '@amplitude/analytics-browser'

export const $amplitudeSetUserId = userId => {
  userId && setUserId(userId)
}

const $amplitude = (event, data) => {
  track(event, data)
}

export default $amplitude
