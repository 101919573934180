import $amplitude from './amplitude'

/* eslint-disable camelcase */

/**
 * [Landing] Stories Try For Free Button Click
 * @param {string} source
 */

export const eventStoriesTryForFreeButtonClick = source => {
  $amplitude('[Landing] Stories Try For Free Button Click', {
    source,
  })
}

/**
 * [Landing] Stories Click
 * @param {string} source
 */

export const eventStoriesClick = type => {
  $amplitude('[Landing] Stories Click', {
    type,
  })
}

/**
 * [Landing] Learn More Button Click
 */

export const eventLearnMoreButtonClick = () => {
  $amplitude('[Landing] Learn More Button Click')
}

/**
 * [Landing] Autoposting How It Works Tab Click
 * @param {string} tabName
 */

export const eventAutopostingHowItWorksTabClick = tabName => {
  $amplitude('[Landing] Autoposting How It Works Tab Click', {
    tabName,
  })
}
